const initialState = {
	inputValue: '',
	isLoading: false,
	requests: [],
	responses: [],
	isAuthenticated: false,
};

const reducer = (state = initialState, action) => {
switch (action.type) {
	case 'SET_INPUT_VALUE':
		return { ...state, inputValue: action.payload };
	case 'SET_LOADING':
		return { ...state, isLoading: action.payload };
	case 'SET_REQUESTS':
		const newRequest = action.payload;
		return {
			...state,
			requests: [...state.requests, [newRequest]]
		};
	case 'SET_RESPONSES':
		const newResponse = action.payload;
		return {
			...state,
			responses: [...state.responses, [...newResponse]]
		}
	case 'CLEAR_CHATS':
		return {
			...state,
			requests: action.payload,
			responses: action.payload
		}
	default:
		return state;
}
};

export default reducer;
  