import React from "react";

const Message = ({ requests, responses }) => {
	const errorMessage = 'Error in fetching response.';
	return (
		<>
			{requests && requests.length > 0 && requests.map((request, index) => (
				<div key={index}>
					<div className="text-right mt-12 mb-4">
						<span className="
							inline-flex max-w-100 w-full md:w-auto md:max-w-md lg:max-w-screen-md rounded-3xl min-w-80 text-left
							rounded-ee-none bg-white px-4 py-4 border border-gray-400 shadow-lg text-xs md:text-sm"
						>
							{request}
						</span>
					</div>
					{responses[index] && responses[index].length > 0 && responses[index].map((response, idx) => (
						<div key={idx} className="flex items-end">
							{idx === responses[index].length - 1 && (
								<img src="/icon.png" alt="company logo" className="w-10 h-10" width='2.5rem' height='2.5rem' />
							)}
							<span className={`
								inline-flex max-w-100 w-full md:w-auto md:max-w-md lg:max-w-screen-md min-w-64 rounded-3xl
								${idx === responses[index].length - 1 ? 'rounded-es-none mt-1' : ''}
								${idx !== responses[index].length - 1 ? 'ml-10' : ''}
								bg-gray-100 px-4 py-4 border border-gray-400 shadow-lg text-xs md:text-sm
								${errorMessage === response[0] ? 'bg-red-100' : ''}
								`}
								style={{ whiteSpace: 'pre-line' }}
							>
								{response}
							</span>
						</div>
					))}
				</div>
			))}
		</>
	)
}

export default Message;
