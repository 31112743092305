import Header from './header.jsx';
import Footer from './footer.jsx';
import ChatBot from './chatbot.jsx';
import SideBar from './sidebar.jsx';


const Home = () => {
	return (
		<div>
			<Header />
			<div className='flex min-h-[80vh]'>
				<div className="hidden bg-gray-100 w-64 p-6 min-h-[75vh] md:flex flex-col justify-center">
					<SideBar />
				</div>
				<div className="flex-grow lg:px-6">
					<ChatBot />
				</div>
			</div>
			<Footer className="border-t" />
		</div>
	)
}

export default Home;