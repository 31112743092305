
import React from 'react';
import { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setInputValue, setLoading, setRequests, setResponses } from '../redux/actions';
import Message from './message.jsx';
import Icons from '../lib/icons.js';
import { Spinner } from './spinner.jsx';
import { fetchData } from '../services/api.js';

const ChatBot = () => {
	const dispatch = useDispatch();
	const inputValue = useSelector((state) => state.inputValue);
	const isLoading = useSelector((state) => state.isLoading);
	const responses = useSelector((state) => state.responses);
	const requests = useSelector((state) => state.requests);
	const messagesContainerRef = useRef(null);

    // Effect to scroll to the bottom of the container when it updates.
    useEffect(() => {
        // Scroll to the bottom of the container.
        messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }, [requests, responses]);

	const handleChange = (e) => {
		dispatch(setInputValue(e.target.value));
	};

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
		  handleMessageSubmit();
		}
	};

	const handleMessageSubmit = async () => {
		if( inputValue.trim().length === 0 ) {
			return;
		}
		dispatch(setLoading(true));
		dispatch(setRequests(inputValue.trim()));
		dispatch(setInputValue(''));

		try {
			// Simulate an asynchronous API call
			const data = await fetchData(inputValue.trim());

			// const newResponse = data.stats.map(item => [item.stat.name]);
			const newResponse = [[data.response]];

			// Once the response is received, set isLoading to false.
			dispatch(setLoading(false));
			dispatch(setResponses(newResponse));
			
		} catch (error) {
			dispatch(setResponses([['Error in fetching response.']]));
			console.error('Error:', error);
			dispatch(setLoading(false));
		}
	};

  return (
    <div className="max-h-[80vh] h-full p-4 flex flex-col">
      	{/* Render messages */}
      	<div
			ref={messagesContainerRef}
			className="flex-grow overflow-y-auto flex flex-col"
		>
			<div>
				<Message requests={requests} responses={responses} />
			</div>
			{isLoading && (
				<Spinner className={"mt-4"} />
			)}
		</div>

		{/* Message input */}
		<div className="relative">
			<input
				id="queryInput"
				type="text"
				placeholder="Add your query here..."
				className="mt-4 p-4 border border-gray-300 rounded w-full focus:outline-none"
				value={inputValue}
       			onChange={handleChange}
				onKeyDown={handleKeyDown}
				disabled={isLoading}
			/>
			 {(
				<Icons.SendHorizontal
					height={24}
					width={24}
					className='absolute right-4 transform -translate-y-1/2 cursor-pointer'
					style={{ top: "50%", marginTop: "0.5rem" }}
					onClick={() => !isLoading && handleMessageSubmit()}
				/>
            )}
		</div>
    </div>
  );
};

export default ChatBot;
