export const setInputValue = (value) => ({
	type: 'SET_INPUT_VALUE',
	payload: value,
});

export const setLoading = (isLoading) => ({
	type: 'SET_LOADING',
	payload: isLoading,
});

export const setRequests = (requests) => ({
    type: 'SET_REQUESTS',
    payload: requests,
});

export const setResponses = (responses) => ({
    type: 'SET_RESPONSES',
    payload: responses,
});

export const clearChats = () => ({
	type: 'CLEAR_CHATS',
    payload: [],
})
