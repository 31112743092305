import { useSelector } from 'react-redux';
const MainNav = () => {
	const requests = useSelector((state) => state.requests);

	const preventLoad = (event) => {
		event.preventDefault();
	}

	return (
		<div>
			<a href="/" className="items-center space-x-1 flex" onClick={(event) => {
				if( requests.length > 0 ) {
					const isConfirmed = window.confirm('On Refreshing you will lost all the chats');
	
					if (!isConfirmed) {
						preventLoad(event);
					}
				}
			}}>
				<img src="/icon.png" alt="Company Logo" className="w-10 h-10" width='2.5rem' height='2.5rem' />
				<h1 className="hidden text-xl font-bold sm:inline-block">
					rtBot
				</h1>
			</a>
		</div>
	)
}

export default MainNav;
