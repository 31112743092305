import MainNav from "./main-nav.jsx";
import ClearChat from "./clear-chat.jsx";

const Header = () => {
	return (
		<header className="sticky top-0 z-40 border-b bg-white">
			<div className="container flex h-16 items-center justify-between py-4 lg:max-w-none">
				<MainNav />
				<ClearChat />
			</div>
		</header>
	)
}

export default Header;
