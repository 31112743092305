import { baseUrl } from "./config";

export const fetchData = async (inputValue) => {
    try {
		const url = baseUrl + '/api/query/';
		const payload = {
			query: inputValue,
		};
		const response = await fetch(url, {
			method: 'POST',
			headers: {
			  'Content-Type': 'application/json'
			},
			body: JSON.stringify(payload)
		});

		if (!response.ok) {
			throw new Error('Failed to post data');
		}
	
		const responseData = await response.json();
		return responseData;
    } catch (error) {
        throw new Error('Failed to fetch data');
    }
};
