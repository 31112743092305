const siteConfig = {
	name: "CDP",
	description:
	  "An open source application built using the new router, server components and everything new in Next.js 13.",
	url: "rtcamp.com",
	ogImage:
	  "https://rtcamp.com/wp-content/uploads/sites/2/2021/02/rtCamp-good-work-good-people.png",
	links: {
	  twitter: "https://twitter.com/rtcamp",
	  facebook: "https://facebook.com/rtCamp.solutions",
	  github: "https://github.com/rtCamp",
	  linkedin: "https://linkedin.com/company/rtcamp/",
	},
};

export default siteConfig;
