
import { useDispatch } from 'react-redux';
import { clearChats } from '../redux/actions';

const ClearChat = () => {
	const dispatch = useDispatch();

	const clearAllChats = () => {
		dispatch(clearChats());
	}

	return (
		<div 
			className="flex items-center gap-4 border p-2 rounded-md border-black shadow-sm cursor-pointer"
			onClick={() => {
				const isConfirmed = window.confirm('Are you sure you want to clear the chat? This action cannot be undone.');

				if (isConfirmed) {
					// Perform delete action
					clearAllChats();
				}
			}}
		>
			<span className="font-bold">Clear Chat</span>
		</div>
	);
}

export default ClearChat;
