import siteConfig from "../config/site";
import Icons from "../lib/icons";

const SideBar = () => {
	return (
		<>
			<div className="flex flex-col items-center mb-6">
				<img src="/icon.png" alt="Company Logo" className="w-36 h-36" width='9rem' height='9rem' />
				<p className="text-sm">Visit Us: <a href={`https://${siteConfig.url}`} className="text-blue-600" target="_blank" rel="noreferrer">{siteConfig.url}</a></p>
			</div>
			<div>
				<div className="flex gap-4 justify-center border border-gray-400 rounded-3xl p-4 bg-white">
					<a href={siteConfig.links.twitter} target="_blank" rel="noreferrer">
						<Icons.Twitter width={16} height={16} />
					</a>
					<a href={siteConfig.links.facebook} target="_blank" rel="noreferrer">
						<Icons.Facebook width={16} height={16} />
					</a>
					<a href={siteConfig.links.linkedin} target="_blank" rel="noreferrer">
						<Icons.Linkedin width={16} height={16} />
					</a>
					<a href={siteConfig.links.github} target="_blank" rel="noreferrer">
						<Icons.Github width={16} height={16} />
					</a>
				</div>
			</div>
		</>
	);
}

export default SideBar;
